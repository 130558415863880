import React from "react";

import "./DocumentAsset.css";

const DocumentAsset = ({ asset, dark, displayText, isPdf = false }) => {
  const colorClass = dark ? "darken" : "";

  const iconPdf = dark ? "/images/pdf_white.png" : "/images/pdf.png";

  const icon = dark ? "/images/docx_white.png" : "/images/word.png";

  return (
    <a
      href={asset}
      className={`document-asset document-asset--${colorClass}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        src={isPdf ? iconPdf : icon}
        className="document-asset__icon"
        alt="asset"
      />

      <div className={`document-asset__title ${colorClass}`}>
        <span>{displayText}</span>
      </div>
    </a>
  );
};

export { DocumentAsset };
