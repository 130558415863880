import React from "react";

import "./Input.css";

function Input({
  label,
  value,
  type,
  name,
  id,
  onChange,
  checked = false,
  errors,
}) {
  return (
    <>
      <div
        className={`form-input ${type === "checkbox" ? "form-checkbox" : ""}`}
      >
        <label htmlFor={id} className="label">
          {label}
        </label>
        <input
          type={type}
          name={name}
          id={id}
          className="input"
          value={value}
          onChange={onChange}
          checked={checked}
          required
        />
      </div>
      {errors && errors[id] && <div className="error-msg">*{errors[id]}</div>}
    </>
  );
}

export default Input;
