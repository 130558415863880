import React from "react";
import { useTranslation } from "react-i18next";
import { DocumentAsset } from "../DocumentAsset/DocumentAsset";

import "./Footer.css";

function Footer() {
  const { t } = useTranslation();
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="contact">
          <div className="contact-title">{t("contactTitle")}</div>
          <div className="contact-phone">0800 808 809</div>
        </div>
        <div className="documents">
          <DocumentAsset
            asset={t("infoDocument")}
            displayText={t("infoDocumentTitle")}
            dark
            isPdf
          />
        </div>
      </div>
    </footer>
  );
}

export default Footer;
