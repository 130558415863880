import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import { languageService } from '../services/languageService'

import translationSR from '../locales/sr/translation.json'
import translationEn from '../locales/en/translation.json'

const resources = {
    sr: {
        translation: translationSR
    },
    en: {
        translation: translationEn
    }
}

i18n.use(initReactI18next).init({
    resources,
    lng: languageService.activeLanguage ? languageService.activeLanguage : 'sr',

    // debug: true,

    keySeparator: false,
    interpolation: {
        escapeValue: false // not needed for react!!
    },
    returnObjects: true
})

export default i18n
