import Header from './components/Header/Header';
import Home from './pages/Home/Home';
import {
  Switch,
  Route,
} from "react-router-dom";
import Footer from './components/Footer/Footer';
import ThankYou from './pages/ThankYou/ThankYou';
import { PageInfo } from './components/PageInfo/PageInfo';

function App() {

  return (
    <>
      <Header />
      <Switch>
        <Route path="/thank-you" component={ThankYou} />
        <Route path="/" component={Home} />
      </Switch>
      <Footer />
    </>
  );
}

export default App;
