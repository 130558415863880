import React, {  useState } from "react";
import { useTranslation } from "react-i18next";
import { languageService } from "../../services/languageService";

import "./Header.css";

const Header = () => {
  const { t } = useTranslation();
  const [lng, setLng] = useState(
    languageService.getActiveLanguage("language") || "sr"
  );

  const onSetLanguage = (e) => {
    const lang = e.currentTarget.getAttribute("data-lang");
    languageService.setActiveLanguage("language", lang);
    setLng(lang);
    window.location.reload();
  };

  return (
    <header className="header">
      <div className="header-container">
        <div className="logo">
          <img className="logo-image" src={t("logo")} alt="logo"/>
        </div>
        <div className="header__content">
          <div className="content-info">
            Call center <span>0800 808 809</span>
          </div>
          <a className="content-info" href="mailto:inocovid19@pks.rs">
            inocovid19@pks.rs
          </a>
          <ul className="header__content__options">
            <li
              onClick={onSetLanguage}
              data-lang="sr"
              className={lng === "sr" ? "active-language" : ""}
            >
              sr
            </li>
            <li
              onClick={onSetLanguage}
              data-lang="en"
              className={lng === "en" ? "active-language" : ""}
            >
              en
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
};

export default Header;
