import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Input from "../Input/Input";

import "./Dropdown.css";

function Dropdown({ id, label, dropdownItems, onChoose, errors }) {
  const [isOpen, setIsOpen] = useState(false);
  const [chosenItem, setChosenItem] = useState("");
  const [inputValue, setInputValue] = useState("");
  const { t } = useTranslation();

  const onSetChosenItem = (e) => {
    const chosenItemValue = e.currentTarget.textContent;

    setChosenItem(chosenItemValue);
    onChoose(chosenItemValue);
  };

  const onChangeInput = (e) => {
    const value = e.currentTarget.value;
    setInputValue(value);
    setChosenItem(value);
    onChoose(value);
  };

  useEffect(() => {
    document.body.addEventListener("click", (e) => {
      if (
        !e.target.classList.contains("chosen-item") &&
        !e.target.classList.contains("input")
      ) {
        setIsOpen(false);
      }
    });
  });

  return (
    <>
      <div className="dropdown-container">
        <label htmlFor={id} className="dropdown-label">
          {label}
        </label>
        <div className="dropdown">
          <div
            className={`chosen-item ${isOpen ? "opened" : ""}`}
            onClick={() => setIsOpen((prev) => !prev)}
          >
            {chosenItem}
          </div>
          {isOpen && (
            <div className="dropdown-items-container">
              {dropdownItems.map((dropdownItem) => {
                return (
                  <div
                    className="dropdown-item"
                    onClick={onSetChosenItem}
                    key={dropdownItem}
                  >
                    {dropdownItem}
                  </div>
                );
              })}
              <Input
                label={t("dropdownItem8")}
                type="text"
                value={inputValue}
                onChange={onChangeInput}
              />
            </div>
          )}
        </div>
      </div>
      {errors && errors[id] && <div className="error-msg">*{errors[id]}</div>}
    </>
  );
}

export default Dropdown;
