import React from 'react'
import PropTypes from 'prop-types'

import { SectionTitle } from '../SectionTitle/SectionTitle'

import './PageInfo.css'

const PageInfo = ({
    title,
    bgImage,
    overlay = false,
}) => {
    bgImage = bgImage || '/images/top-img.jpg'
    const className = overlay ? 'overlay' : ''

    return (
        <section
            className={`page-info page-info--${className} `}
            style={{backgroundImage: `url("${bgImage}")`}}
        >
            <div className="container-1280">
                <div className="page-info__content">
                    <div className="page-info__content__title">
                        <SectionTitle title={title} underlineClass dark />
                    </div>
                </div>
            </div>
        </section>
    )
}

PageInfo.propTypes = {
    title: PropTypes.string,
    overlay: PropTypes.bool
}

export { PageInfo }
