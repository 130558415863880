import { http } from "./httpService";

class FormService {

    async postForm(body) {
        const path = '/inocovid-form'
        const response = await http.post(path, body)

        return response

    }
}

export const formService = new FormService()