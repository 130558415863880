import { storageService } from './storageService'

class LanguageService {
    constructor() {
        this.languageList = ['sr', 'en']
        this.activeLanguage = this.getActiveLanguage('language')
    }

    getLocaleLang = () => {
        const activeLang = this.activeLanguage
        let langLocale = 'sr'

        switch (activeLang) {
            case 'sr':
                langLocale = 'sr'
                break
            case 'en':
                langLocale = 'en'
                break
        }
        return langLocale
    }

    getDefaultLanguage() {
        return this.languageList[0]
    }

    setActiveLanguage(key, language) {
        storageService.set(key, language)
        this.activeLanguage = storageService.get(key)
    }

    getActiveLanguage(key) {
        const activeLanguage = storageService.get(key)
        return activeLanguage || this.getDefaultLanguage()
    }

    getAllLanguages() {
        return this.languageList
    }
}

export const languageService = new LanguageService()
