import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { PageInfo } from "../../components/PageInfo/PageInfo";
import Form from "../../components/Form/Form";
import { DocumentAsset } from "../../components/DocumentAsset/DocumentAsset";

import "./Home.css";

function Home() {
  const { t } = useTranslation();

  useEffect(() => {
    const isRedirectedFromEngCheckInit = window.location.href.split('?')[1]
    if (isRedirectedFromEngCheckInit == 'from-eng') {
      localStorage.setItem('language', '"en"')
      window.location.href = "/"
    }
  }, [])


  return (
    <main className="main">
      <PageInfo title={t("title")} bgImage="/images/about-us-bg.jpg" overlay />
      <div className="home">
        <section className="text-section underlined greetings-section-mobile">
          <p>{t("greetingsText")}</p>
        </section>
        <div className="content">
          <div className="left-col">
            <section className="text-section underlined greetings-section-desktop">
              <p>{t("greetingsText")}</p>
            </section>
            <section className="text-section underlined">
              <p>
                <strong className="red-text">{t("note")}: </strong>
                {t("noteText")} <br />
                {t("noteText2")} <br />
                <span className="bold-letters">
                  {t("noteText3Strong")}
                </span>
                {t("noteText3")} <br />
                <span className="bold-letters">
                  {t("noteText4Strong")}
                </span>
                {t("noteText4")} <br />
                <span className="bold-letters">
                  {t("noteText5Strong")}
                </span>
                {t("noteText5")} <a href={t("noteText5Link")} target="_blank" rel="noopener noreferrer">{t("noteText5Link")}</a>
                {t("noteText5Part2")}
              </p>
            </section>
            <section className="text-section blue">
              <h6 className="text-section-title">{t("procedure")}</h6>
              <ul className="text-section-list">
                <li>{t("procedure1")}</li>
                <li>{t("procedure2")}</li>
                <li>{t("procedure3")}</li>
              </ul>
            </section>
          </div>
          <div className="right-col">
            <section className="text-section">
              <p>
                {t("formIntro1")} <strong>{t("formIntro2")} </strong>
                {t("formIntro3")}
                <a href="mailto:inocovid19@pks.rs"> inocovid19@pks.rs</a>.
              </p>
            </section>
            <div className="text-section blue">{t("formIntroBoxed")}
              <br />
              <br />
              {t("formIntroBoxed2")}
              <br />
              <br />
              <b>{t("formIntroBoxed3")}</b></div>
            <Form />
          </div>
        </div>
        <section className="text-section grey">
          <div className="text-section-container">
            <p className="note-text">
              <span className="note-icon">
                <img src="/images/informacija.png" />
              </span>
              {t("info")}
            </p>
          </div>
        </section>
        {/* <section className="text-section">
          <div className="text-section-container">
            <h6 className="text-section-title">{t("documentsTitle")}</h6>
            <div className="documents">
              <DocumentAsset
                asset={t("instructionDoc")}
                displayText={t("instructionDocTitle")}
                isPdf
              />
              <DocumentAsset
                asset={t("instructionDoc2")}
                displayText={t("instructionDocTitle2")}
                isPdf
              />
              <DocumentAsset
                asset={t("demandsDoc")}
                displayText={t("demandsDocTitle")}
                isPdf
              />
            </div>
          </div>
        </section> */}

        {/* <section className="text-section grey">
          <div className="text-section-container">
            <h6 className="text-section-title">{t("refLaboratorijeInfoTitle")}</h6>
            <p className="note-text">
              <span className="note-icon">
                <img src="/images/informacija.png" />
              </span>
              {t("refLaboratorijeInfo")}
            </p>
            <a className="external-link" href="https://www.zdravlje.gov.rs/tekst/345433/baza-podataka.php" target="_blank" rel="noopener noreferrer">{t("refLaboratorijeExternalLinkTitle")}</a>
          </div>
        </section> */}
      </div>
      <div className="info-modal-container">
        <div className="info-modal-inner-wrapper">
          <div className="text-section limit-width">
            <p>COVID-19 – Entry Conditions <br />
              (updated 05/03/2022) </p>
            <p>There are <b>no restrictions</b> on entering the Republic of Serbia.</p>
            <p>The recommendation to wear masks indoors remains in force, while wearing masks in health facilities is mandatory.</p>
          </div>
          <div className="horizontal-line"> </div>
          <div className="text-section limit-width">
            <p> COVID-19 – Uslovi za ulazak<br />
              (ažurirano 03.05.2022)</p>
            <p><b>Nema ograničenja</b> za ulazak u Republiku Srbiju</p>
            <p>Preporuka za nošenje maski u zatvorenom prostoru ostaje na snazi, dok je nošenje maski u zdravstvenim ustanovama obavezno.</p>
          </div>

        </div>
      </div>
    </main>
  );
}

export default Home;
