import axios from 'axios'

import { BASE_URL } from '../config/constants'



class Http {
    axiosInstance = axios.create({
        baseURL: BASE_URL,
        headers: {
            Accept: 'application/json',
        }
    });

    async post(path, body) {
        const res = await this.axiosInstance.post(path, body)

        return res.data
    }
}

export const http = new Http()
