import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { formService } from "../../services/formService";

import { SectionButton } from "../SectionButton/SectionButton";
import Dropdown from "./Dropdown/Dropdown";
import Input from "./Input/Input";

import "./Form.css";

function Form() {
  const { t } = useTranslation();
  const [nameValue, setNameValue] = useState("");
  const [travelValue, setTravelValue] = useState("");
  const [companyToVisit, setCompanyToVisit] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [dateNumbers, setDateNumbersValue] = useState("");
  const [duration, setDuration] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [reasonForVisit, setReasonForVisit] = useState("");
  const [confirm, setConfirm] = useState(false);
  const [agree, setAgree] = useState(false);
  const [errors, setErrors] = useState(null);

  const dropdownItems = [
    t("dropdownItem1"),
    t("dropdownItem2"),
    t("dropdownItem3"),
    t("dropdownItem4"),
    t("dropdownItem5"),
    t("dropdownItem6"),
    t("dropdownItem7"),
  ];

  const onChooseReasonForVisit = (reason) => {
    setReasonForVisit(reason);
  };

  const history = useHistory();

  const onSubmit = async (e) => {
    e.preventDefault();

    const data = {
      name: nameValue,
      travel: travelValue,
      companyToVisit,
      companyName,
      reasonForVisit,
      date: dateNumbers,
      duration,
      address,
      phone,
      email,
      confirm,
      agree,
    };
    try {
      const response = await formService.postForm(data);
      if (response.errors) {
        setErrors(response.errors);
      }

      if (response.success) {
        setErrors(null);
        history.push("/thank-you");

      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <form className="form">
      <Input
        label={t("nameLastName")}
        id="name"
        type="text"
        value={nameValue}
        name={t("nameLastName")}
        onChange={(e) => setNameValue(e.currentTarget.value)}
        errors={errors}
      />
      <Input
        label={t("travelLicence")}
        id="travel"
        type="text"
        value={travelValue}
        name={t("travelLicence")}
        onChange={(e) => setTravelValue(e.currentTarget.value)}
        errors={errors}
      />
      <Input
        label={t("companyToVisit")}
        id="companyToVisit"
        type="text"
        value={companyToVisit}
        name={t("companyToVisit")}
        onChange={(e) => setCompanyToVisit(e.currentTarget.value)}
        errors={errors}
      />
      <Dropdown
        id="reasonForVisit"
        label={t("dropdownLabel")}
        dropdownItems={dropdownItems}
        onChoose={onChooseReasonForVisit}
        errors={errors}
      />
      <Input
        label={t("companyName")}
        id="companyName"
        type="text"
        value={companyName}
        name={t("companyName")}
        onChange={(e) => setCompanyName(e.currentTarget.value)}
        errors={errors}
      />
      <Input
        label={t("dateNumbersEtc")}
        id="date"
        type="text"
        value={dateNumbers}
        name={t("dateNumbersEtc")}
        onChange={(e) => setDateNumbersValue(e.currentTarget.value)}
        errors={errors}
      />
      <Input
        label={t("duration")}
        id="duration"
        name={t("duration")}
        type="text"
        value={duration}
        onChange={(e) => setDuration(e.currentTarget.value)}
        errors={errors}
      />
      <Input
        label={t("address")}
        type="text"
        value={address}
        name={t("address")}
        id="address"
        onChange={(e) => setAddress(e.currentTarget.value)}
        errors={errors}
      />
      <Input
        label={t("phone")}
        name={t("phone")}
        id="phone"
        type="text"
        value={phone}
        onChange={(e) => setPhone(e.currentTarget.value)}
        errors={errors}
      />
      <Input
        label={t("email")}
        name={t("email")}
        id="email"
        type="text"
        value={email}
        onChange={(e) => setEmail(e.currentTarget.value)}
        errors={errors}
      />
      <Input
        label={t("confirmData")}
        name={t("confirmData")}
        id="confirm"
        type="checkbox"
        checked={confirm}
        onChange={(e) => setConfirm((prev) => !prev)}
        errors={errors}
      />
      <Input
        label={t("agree")}
        name={t("agree")}
        id="agree"
        type="checkbox"
        checked={agree}
        onChange={(e) => setAgree((prev) => !prev)}
        errors={errors}
      />
      <div className="button-container">
        <div className="input-submit">
          <SectionButton
            buttonText={t("formButton")}
            dark
            onClickHandler={onSubmit}
          />
        </div>
      </div>
    </form>
  );
}

export default Form;
