import React from "react";
import { useTranslation } from "react-i18next";
import { PageInfo } from "../../components/PageInfo/PageInfo";
import { SectionButton } from "../../components/SectionButton/SectionButton";
import { withRouter } from "react-router-dom";

import "./ThankYou.css";

function ThankYou({ history }) {
  const { t } = useTranslation();

  const onGoBack = () => {
    history.push("/");
  };
  return (
    <main className="main">
      <PageInfo title={t("title")} bgImage="/images/about-us-bg.jpg" overlay />

      <div className="thank-you">
        <div className="thank-you-text">{t("thankYou")}</div>
        <div className="thank-you-text">{t("thankYouText")}</div>
        <SectionButton
          buttonText={t("goBack")}
          dark
          onClickHandler={onGoBack}
        />
      </div>
    </main>
  );
}

export default withRouter(ThankYou);
